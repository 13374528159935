import { FC, useMemo, memo } from 'react';
import { Button, Flex, Heading, Modal, Paragraph, Tabs } from '@components';
import { slideUp } from '@utils/motions';
import variables from '@styles/export.module.scss';
import { INFO_TAB_DESCRIPTION_COPY } from '@utils/constants/info-module';
import styles from './EducationModal.module.scss';

type tabOptions = {
	description: string;
	checkmark: boolean;
	justify?: boolean;
};

type TabsProps = {
	title: string;
	list: Array<tabOptions>;
};
interface EducationModalProps {
	tabs: Array<TabsProps>;
	title: string;
	tab: number;
	triggerClassName?: string;
	subTitle: string;
	onlyProgressives?: boolean;
	isOpt?: boolean;
	withSubtitle?: boolean;
	triggerLabel?: string;
}

const EducationModal: FC<EducationModalProps> = ({
	tabs,
	title,
	tab,
	triggerClassName,
	subTitle,
	onlyProgressives,
	isOpt,
	withSubtitle,
	triggerLabel = 'Learn more',
	...props
}) => {
	let defaultTabs = tabs;
	if (onlyProgressives) defaultTabs = [tabs[1]];

	const memoTabOptions = useMemo(
		() =>
			defaultTabs.map(({ list, title }, index) => {
				return (
					<Tabs.Content
						key={index}
						role='tabpanel'
						aria-labelledby={`tab${index}`}
						value={`tab${index}`}
						className={styles.tabsContent}
						removeDefaultStyles
					>
						<ul>
							<Heading tag='h6'>{title}</Heading>
							{list.map(({ description }, index) => {
								return (
									<li key={`panel-description-${index}`} className={`${styles['content-list-item']}`}>
										<Paragraph>{description}</Paragraph>
									</li>
								);
							})}
						</ul>
					</Tabs.Content>
				);
			}),
		[defaultTabs]
	);

	const memoTabTriggers = useMemo(
		() =>
			tabs.map(({ title }, index) => (
				<Tabs.Trigger
					key={title}
					value={`tab${index}`}
					id={`tab-${index}`}
					role='tab'
					aria-controls={`tabpanel-${index}`}
					aria-selected={'[data-state="active"]' ? true : false}
					{...{ 'data-education-tab': title }}
				>
					<span>{title}</span>
				</Tabs.Trigger>
			)),
		[tabs]
	);

	return (
		<Modal>
			<Modal.Trigger asChild>
				<Button
					linkStyle
					size={'small'}
					extraClasses={triggerClassName}
					style={{ color: isOpt && variables.gray4 }}
					{...(Object.keys(props).length > 0 ? props : { 'data-learn-more': true })}
				>
					{triggerLabel}
				</Button>
			</Modal.Trigger>
			<Modal.Content className={styles.modal} customAnimation={slideUp}>
				<Flex column justify='between' style={{ height: '100%' }}>
					<div className={styles.container} data-div-name='lens-offerings'>
						{defaultTabs.length > 1 ? (
							<>
								<Heading tag='h5'>{title}</Heading>
								<Flex column gap={3}>
									<Paragraph>{subTitle}</Paragraph>
									<Paragraph small>{INFO_TAB_DESCRIPTION_COPY}</Paragraph>
								</Flex>
								<Tabs defaultValue={`tab${tab}`} role='tablist' aria-label='Prescription lens description'>
									{!onlyProgressives && <Tabs.List aria-label='Offerings'>{memoTabTriggers}</Tabs.List>}
									{memoTabOptions}
								</Tabs>
							</>
						) : (
							<>
								<Heading tag='h5'>{defaultTabs[0].title}</Heading>
								{withSubtitle && (
									<Flex column gap={3}>
										<Paragraph>{subTitle}</Paragraph>
										<Paragraph small>{INFO_TAB_DESCRIPTION_COPY}</Paragraph>
									</Flex>
								)}

								{defaultTabs[0].list.map(({ description, justify }, index) => {
									return (
										<div
											key={`panel-description-${index}`}
											style={{ marginTop: '1.6rem', textAlign: justify ? 'justify' : 'initial' }}
										>
											<li className={`${styles['content-list-item']}`}>
												<Paragraph>{description}</Paragraph>
											</li>
										</div>
									);
								})}
							</>
						)}
					</div>
					<Modal.Close asChild>
						<Button color='white' style={{ width: '100%', marginBottom: '3.2rem' }} aria-label='Close'>
							Close
						</Button>
					</Modal.Close>
				</Flex>
			</Modal.Content>
		</Modal>
	);
};

export default memo(EducationModal);
